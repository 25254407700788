/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-tabs-header {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.altai-tabs-header div {
  padding: 0 20px 10px 20px;
}
.altai-tabs-header div:hover {
  cursor: pointer;
}
.altai-tabs-header .active {
  border-bottom: 2px solid #0068ff;
  font-weight: bold;
  color: #0068ff;
}
.altai-tabs-content {
  padding: 0 20px 20px 20px;
}
